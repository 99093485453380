<script setup>
const props = defineProps(['icon', 'link', 'opensInPlace']);
const target = ref(props.opensInPlace ? '_self' : '_blank');
</script>

<template>
  <a :href="link" :target="target" class="link-list-item">
    <div class="link-list-item__icon">
      <i :class="[`icon-${icon ? icon : 'download'}`]" />
    </div>
    <div class="link-list-item__text"><slot /></div>
  </a>
</template>

<style lang="scss" scoped>
.link-list-item {
  $s: &;

  .main[style*='background-color'] & {
    border-color: $black;
  }

  display: flex;
  padding: 12px;
  border-bottom: 1px solid $silver;
  font-size: 16px;
  line-height: 1.5;

  &:first-child {
    border-top: 1px solid $silver;
  }

  &__icon {
    flex-shrink: 0;
    width: 32px;
  }

  &__text {
  }

  &:hover {
    #{$s}__text {
      text-decoration: underline;
    }
  }
}
</style>
